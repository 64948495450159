import React from "react";
import Slider from "../components/home/SliderComp";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import useLocalStorage from "../components/useLocalStorage";
import TopBar from "../components/Layout/TopBar";
import Seo from "../components/seo";
import SEO from "../components/seo";
import EntryFormButton from "../components/EntryFormButton";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import hero from '../images/comp/prize-img.jpg';
import hero2 from '../images/comp/hamper-main-2023-new.jpg';
import { Field, Form, Formik, ErrorMessage } from "formik";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import { FaSpinner } from "react-icons/fa";

const encode = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};
const INITIAL = {
  "form-name": "event-data",
  "bot-field": "",
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  dog: "",
  tos: "",
};
export default function Competition() {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { regex: "/comp/prize-img.jpg/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [data, setData] = useLocalStorage("form", JSON.stringify(INITIAL));

  return (
    <>
    {/* <SEO title="Event data collection" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Event data collection"
      keywords={[`great`, `british`, `dogs`]}
    />
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>

      <div className="bg-primary ">
        <div className="container ">
          <div
            className=" max-w-2xl  mx-auto  py-12 "
            css={css`
              label {
                ${tw`text-white block mb-2`}
                &.radio {
                  ${tw`inline-flex items-center text-lg w-3/5`}
                  input {
                    ${tw`ml-3 w-8 h-8

                  `}
                  }
                }
              }
              input,
              textarea,
              select {
                ${tw`rounded-xl w-full text-lg py-2 px-4 outline-none block bg-white`}
              }
              select {
                border-right: 10px solid white;
              }
              textarea {
                min-height: 250px;
              }
              .file-selector {
                ${tw`rounded-xl w-full text-lg py-2 px-4 outline-none block bg-white text-black flex items-center justify-between mt-2`}
              }
              .error {
                ${tw`mt-1 text-yellow-500`}
              }
            `}
          >
            <h1
              css={css`
                ${tw`text-primary font-slab font-bold text-left pb-5 text-2xl text-white justify-center`}
              `}
            >
              <span css={css`display: block !important;text-align: center !important;`}>Enter your details</span>
            </h1>
            <Formik
              initialValues={JSON.parse(data)}
              validationSchema={yup.object({
                email: yup
                  .string()
                  .email("This has to be a valid email")
                  .required("This field is required!"),
                firstName: yup.string().required("This field is required!"),
                lastName: yup.string().required("This field is required!"),
                dog: yup.string().required("This field is required!"),
                tos: yup.string().required("This field is required!"),
              })}
              onSubmit={(values, actions) => {
                fetch("/", {
                  method: "POST",
                  // headers: {
                  //   "Content-Type": "multipart/form-data; boundary=random",
                  // },
                  body: encode(values),
                })
                  .then(() => {
                    actions.resetForm();
                    setData(JSON.stringify(INITIAL));
                    navigate("/thanks-data");
                  })
                  .catch((error) => {
                    alert(error);
                  });
              }}
            >
              {({ values, touched, errors, setFieldValue, isSubmitting }) => {
                console.log(errors);
                return (
                  <Form
                    name="event-data"
                    method="post"
                    action="/thanks-data"
                    data-netlify="true"
                    onChange={(e) => {
                      setData(
                        JSON.stringify({
                          ...JSON.parse(data),
                          [e.target.name]: e.target.value,
                        })
                      );
                    }}
                    data-netlify-honeypot="bot-field"
                  >
                    {" "}
                    <p class="hidden">
                      <label id="contact-form-bot-label">
                        Don't fill this out if you're human:{" "}
                        <input
                          name="bot-field"
                          aria-labelledby="contact-form-bot-label"
                        />
                      </label>
                    </p>
                    <input type="hidden" name="form-name" value="event-data" />
                    <div className="space-y-8">
                      <div>
                        <label>* Email</label>
                        <Field type="email" name="email"></Field>
                        <ErrorMessage name="email">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label>Phone number (Optional)</label>
                        <Field type="text" name="phone"></Field>
                      </div>
                      <div>
                        <label>* Your first name</label>
                        <Field type="text" name="firstName"></Field>
                        <ErrorMessage name="firstName">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label>* Your last name</label>
                        <Field type="text" name="lastName"></Field>
                        <ErrorMessage name="lastName">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div>
                        <label>* Do you have a dog?</label>
                        <Field as="select" name="dog" placeholder="">
                          <option value="" disabled>
                            Please select
                          </option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </Field>
                        <ErrorMessage name="dogAge">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="text-white text-sm">
                        <p className="mb-2">
                          {" "}
                          By ticking yes below, you consent to your data being
                          shared with Nestlé Purina® Petcare UK&I so that they
                          can send you information and offers. Purina® will only
                          contact you via email for marketing purposes and you
                          can update these preferences or unsubscribe at any
                          time. For more information about how Nestlé Purina®
                          may use your personal data, please see their{" "}
                          <a
                            className="underline"
                            target="_blank"
                            href="https://www.purina.co.uk/privacy/"
                          >
                            Privacy Policy.
                          </a>
                        </p>
                      </div>
                      <div>
                        <label className="radio" css={css`width:100%;`}>
                          <span>
                            *Yes, please send me exclusive offers, content and personalised advice
                            <sup>®</sup>{" "}
                          </span>
                          <Field type="radio" name="tos" value={"Yes"}></Field>
                        </label>
                        <label className="radio" css={css`width:100%;`}>
                          <span>*No, I do not want to receive exclusive offers, content and personalised advice</span>
                          <Field type="radio" name="tos" value={"No"}></Field>
                        </label>
                        <ErrorMessage name="tos">
                          {(msg) => <div className="error">{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="text-sm text-white">
                        <p>
                          For more information on how Immediate Media uses your
                          data, see our{" "}
                          <a
                            className="underline"
                            target="_blank"
                            href="https://policies.immediate.co.uk/privacy/"
                          >
                            Privacy Notice.
                          </a>
                        </p>
                      </div>
                      <div>
                        <button
                          disabled={isSubmitting}
                          className="bg-accent leading-none inline-flex items-center justify-center px-6 py-2  text-white rounded-full relative uppercase font-bench  text-xl outline-none focus:outline-none"
                        >
                          Submit
                          {!isSubmitting && (
                            <MdKeyboardArrowRight className="absolute right-0   mr-1" 
                            css={css`
                            top:8px
                            `}/>
                          )}
                          {isSubmitting && (
                            <FaSpinner className="absolute right-0  animate-spin w-4 h-4 mr-1" />
                          )}
                        </button>
                      </div>
                    </div><br /><br />
                  </Form>
                );
              }}
            </Formik><br /><br />
          </div>
        </div>
      </div>
    </>
  );
}
